 <template>
  <b-modal
    id="modal-page-item-error"
    ref="modal-page-item-error"
    centered
    no-close-on-backdrop
  >
    <template #modal-header>
      <h3>{{ $t('modals.pageItemError.title') }}</h3>
    </template>
    <div class="danger-icon text-center">
      <b-icon icon="exclamation-triangle" variant="danger" class="mt-4 mb-4"></b-icon>
      <div class="mx-5 mb-4" v-html="$t('modals.pageItemError.autosaveError')"></div>
      <div class="mt-3 debug-block" v-if="errorMessageRaw">
        <hr>
        <div class="mx-3 d-block text-left mt-2 pb-2 error-msg-block">
            <h4>Error Message:</h4>
            <div class="mb-2" v-html="errorMessageRaw"></div>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button class="p-3" block @click="closeModal">
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalPageItemError',
  props: {
    errorMessage: {
      type: String,
      required: true,
    },
    errorMessageRaw: {
      type: [String, Error, Object],
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$refs['modal-page-item-error'].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.danger-icon svg {
  font-size: 80px;
}
.debug-block {
    h4 {
        font-size: 13px;
        font-weight: bold;
    }
    p, div {
        font-size: 12px;
    }
}
</style>
