var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal-page-item-error",
      attrs: {
        id: "modal-page-item-error",
        centered: "",
        "no-close-on-backdrop": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function() {
            return [
              _c("h3", [_vm._v(_vm._s(_vm.$t("modals.pageItemError.title")))])
            ]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function() {
            return [
              _c(
                "b-button",
                {
                  staticClass: "p-3",
                  attrs: { block: "" },
                  on: { click: _vm.closeModal }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "danger-icon text-center" },
        [
          _c("b-icon", {
            staticClass: "mt-4 mb-4",
            attrs: { icon: "exclamation-triangle", variant: "danger" }
          }),
          _c("div", {
            staticClass: "mx-5 mb-4",
            domProps: {
              innerHTML: _vm._s(_vm.$t("modals.pageItemError.autosaveError"))
            }
          }),
          _vm.errorMessageRaw
            ? _c("div", { staticClass: "mt-3 debug-block" }, [
                _c("hr"),
                _c(
                  "div",
                  {
                    staticClass:
                      "mx-3 d-block text-left mt-2 pb-2 error-msg-block"
                  },
                  [
                    _c("h4", [_vm._v("Error Message:")]),
                    _c("div", {
                      staticClass: "mb-2",
                      domProps: { innerHTML: _vm._s(_vm.errorMessageRaw) }
                    })
                  ]
                )
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }